import React from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider } from "styled-components";

import theme from "../../../styles/theme";
import GlobalStyles from "../../../styles/GlobalStyles";
import "../../../static/fonts/fonts.css";

const SiteContainer = styled.div`
    width: 100%;
    height: 100%;
    ${props => {
        return props.isDark ? "background: #3a3e45;" : "";
    }}
`;

const Layout = ({ children, isDark = false }) => (
    <ThemeProvider theme={theme}>
        <SiteContainer isDark={isDark}>
            <GlobalStyles />
            {children}
        </SiteContainer>
    </ThemeProvider>
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
